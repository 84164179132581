import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import style from "./App.module.css";
import { incrementCount } from "./Api";
import JaipurDivision from "./components/Organisation/JaipurDivison";
import AjmerDivision from "./components/Organisation/AjmerDivison";
import JodhpurDivison from "./components/Organisation/JodhpurDivison";
import BikanerDivision from "./components/Organisation/BikanerDivison";
import HQWorkshop from "./components/Organisation/HQWorkshop";
import Homepage from "./components/Homepage/Homepage";
import Organisation from "./components/Organisation/Organisation";
import About from "./components/About&Contact/About";
import ImportantLinks from "./components/impLinks/ImpLinks";
import Footer from "./components/Footer/Footer";
import Admin from "./components/Admin/Admin";
import Nwreu from "./components/PNM-NWREU/PNM-Nwreu";
import UnionTimes from "./components/UnionTimes/UnionTimes";
import RefNwreu from "./components/PNM-NWREU/Ref-NWREU";
import ImpFormats from "./components/impLinks/impFormats";
import OtherRail from "./components/Organisation/OtherRail";
import AgendaItems from "./components/PNM-NWREU/AgendaItems";
import RefAirf from "./components/PNM-NWREU/Ref-AIRF";
import Header2 from "./components/Header/Header copy";
import Navbar from "./components/Header/navbar";
import JCM from "./components/PNM-NWREU/JCM";
import GoogleTranslate from "./GoogleTranslate";
import Notification from "./components/PNM-NWREU/Notification";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Increment count on initial load and route change
    incrementCount();
  }, [location]);

  return (
    <div className={style.container}>
      <GoogleTranslate />
      <Header2 />
      <Navbar />
      <div className={style.content}>
        <Routes>
          <Route path="/" element={<Homepage hasBanner={true} />} />
          <Route path="/About" element={<About />} />
          <Route path="/Organisation" element={<Organisation />} />
          <Route path="/JaipurDivision" element={<JaipurDivision />} />
          <Route path="/AjmerDivision" element={<AjmerDivision />} />
          <Route path="/JodhpurDivision" element={<JodhpurDivison />} />
          <Route path="/BikanerDivision" element={<BikanerDivision />} />
          <Route path="/HQWorkshop" element={<HQWorkshop />} />
          <Route path="/ImpLinks" element={<ImportantLinks />} />
          <Route path="/AdminPanel" element={<Admin />} />
          <Route path="/PNM-NWREU" element={<Nwreu />} />
          <Route path="/JCM" element={<JCM />} />
          <Route path="/RefNWREU" element={<RefNwreu />} />
          <Route path="/RefAIRF" element={<RefAirf />} />
          <Route path="/UnionTimes" element={<UnionTimes />} />
          <Route path="/ImpFormats" element={<ImpFormats />} />
          <Route path="/OtherRail" element={<OtherRail />} />
          <Route path="/AgendaItems" element={<AgendaItems />} />
          <Route path="/Notifications" element={<Notification />} />
          {/* <Route path="/RetdEmp" element={<RetdEmp />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
